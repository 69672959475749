/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

a {
    color: inherit;
  }
  
  p {
    margin-bottom: 0 !important;
  }
  
  label {
    margin-bottom: 0 !important;
  }
  
  .parent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .header:hover {
    background-color: #ffffff;
  }
  .header:hover .basket-badge {
    color: #fff !important;
  }
  .header:hover * {
    color: black !important;
  }
  .header:hover svg {
    fill: black !important; /* Changes the fill color of the SVG */
    stroke: black !important; /* Changes the stroke color of the SVG, if applicable */
  }
  .scrolled-header {
    background-color: #ffffff;
    color: black !important;
    fill: black !important;
    stroke: black !important;
  }
  .scrolled-header * {
    color: black !important;
  }
  .scrolled-header input[type="search"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='16' viewBox='0 0 14 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.4766 6.48633C11.4766 9.24775 9.23799 11.4863 6.47656 11.4863C3.71514 11.4863 1.47656 9.24775 1.47656 6.48633C1.47656 3.7249 3.71514 1.48633 6.47656 1.48633C9.23799 1.48633 11.4766 3.7249 11.4766 6.48633ZM9.62534 11.5947C8.71005 12.1601 7.63141 12.4863 6.47656 12.4863C3.16285 12.4863 0.476562 9.80004 0.476562 6.48633C0.476562 3.17262 3.16285 0.486328 6.47656 0.486328C9.79027 0.486328 12.4766 3.17262 12.4766 6.48633C12.4766 8.28581 11.6844 9.90028 10.4297 11L13.5236 14.9629L12.7353 15.5783L9.62534 11.5947Z' fill='black'/%3E%3C/svg%3E");
  }
  #header-wrapper {
    position: sticky;
    z-index: 99;
    top: 0;
  }
  /* .container {
      padding-left: 0 !important;
      padding-right: 0 !important;
  } */
  .pointer .location {
    padding: 0 8px;
  }
  
  header div section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* padding: var(--space-2) 0; */
  }
  .bottom-header {
    display: flex; /* Use Flexbox for alignment */
    justify-content: flex-end; /* Aligns both divs to the end of the row */
    align-items: center; /* Vertically centers the items */
    padding-top: 16px; /* Optional: add some padding */
  }
  
  .middle-header {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 62px;
    color: white;
    /* margin-left: auto; */
  }
  
  .services:hover {
    /* border-bottom: 1px solid black; */
    text-decoration: underline;
    text-underline-offset: 8px;
    /* line-height: 50px; */
  }
  .region-container {
    display: flex; /* Use Flexbox for alignment */
    justify-content: space-between; /* Push the radio button to the end of the line */
    align-items: center; /* Center the label and radio button vertically */
    padding: 5px 0; /* Optional: Add some padding */
  }
  
  .region-label {
    cursor: pointer; /* Make sure the cursor changes to pointer */
    display: inline-block; /* Label content remains on the left */
  }
  
  .header:hover .middle-header {
    color: black;
  }
  
  header div section .left-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: var(--space-1);
  }
  
  header div section .right-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: var(--space-2);
    color: white;
  }
  
  .cart {
    position: relative;
  }
  
  .basket-badge {
    background-color: var(--danger-color);
    color: var(--light-color) !important;
    width: 15px;
    height: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: 0.1rem;
    right: -0.5rem;
    padding: 4;
  }
  
  .vertical-line {
    border-left: 1px solid white;
    height: 20px;
  }
  
  .horizontal-line {
    height: 2px;
    background-color: black;
    width: 100%;
  }
  
  .carousel {
    width: 100%;
    height: auto;
    border-radius: 0;
    margin: 0 auto;
    position: relative;
    top: -145px;
  }
  
  .banner {
    border-radius: 0;
  }
  
  .carousel img {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  
  .category-images {
    padding-bottom: 132px;
  }
  
  .category-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr var(--space-3) 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 100%;
    overflow: hidden;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: var(--space-3);
  }
  
  .category-card {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .category-container img {
    width: 100%;
    height: auto;
    /* object-fit: cover; */
  }
  
  .category-card > img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* border-radius: var(--space-2); */
  }
  
  .category-info {
    position: absolute;
    top: 0; /* Move the text to the top of the image */
    width: 100%;
    padding: var(--space-2);
  }
  
  .category-text {
    font-size: 36px;
    line-height: normal;
    color: var(
      --light-color
    ); /* Light color for better contrast with the background */
    font-weight: 600;
    padding-bottom: 24px;
  }
  .style-text {
    font-size: 18px;
    color: white;
    text-decoration: underline;
    line-height: normal;
    font-weight: 400;
    padding-bottom: 24px;
  }
  
  .icon-container {
    border: 1px solid #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: var(--space-2);
    padding: 10px;
  }
  
  .trend-products-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* padding-top: var(--space-2); */
    /* padding-bottom: var(--space-2); */
  }
  
  .trend-products {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: var(--space-3);
    padding-top: var(--space-2);
  }
  
  .item-card {
    background-color: #fff;
    position: relative;
    width: 279px;
    min-height: 490px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  .item-card-skeleton {
    background-color: #fff;
    position: relative;
    /* width: 279px; */
    min-height: 490px;
  }
  .triangle-container {
    position: absolute;
    width: 74px;
    height: 74px;
    top: 0;
    right: 0;
    background-color: #faf9f7;
    z-index: 1;
  }
  .triangle {
    transition: all 0.3s ease-in-out;
    border-bottom: 74px solid;
    border-right: 74px solid transparent;
  }
  .triangle-container_text {
    position: absolute;
    bottom: 0;
    left: 3px;
    font-weight: bold;
    color: white;
  }
  .item-card:hover .triangle {
    filter: brightness(90%);
  }
  .card-image-skeleton {
    margin: 0 10px;
  }
  /* .image-skeleton {
      position: relative;
      height: 370px;
      width: 100%;
      padding-top: 0;
      margin: 0 5px 10px 2px;
      border-radius: var(--space-2);
  } */
  .small-infos-skeleton {
    margin: 15px 0;
    height: 30px;
  }
  
  .bottom-skeleton {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 15px 10px;
  }
  .bottom-skeleton .left-skeleton {
    margin-right: var(--space-3);
    margin-left: 0;
  }
  
  .bottom-skeleton .right-skeleton {
    flex: 1;
  }
  
  .secondary-button {
    background-color: rgba(41, 72, 141, 0.15);
    color: var(--primary-color);
    padding: var(--space-2) var(--space-3);
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 500;
  }
  
  .secondary-button:hover {
    background-color: var(--primary-color);
    color: var(--light-color);
  }
  
  .bold {
    font-weight: 700;
  }
  
  .mt-1 {
    margin-top: var(--space-1);
  }
  
  .features {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: var(--space-3);
  }
  
  .feature-text {
    width: 100%;
  }
  
  .feature-card {
    width: 100%;
    position: relative;
    padding-top: 100%;
    background-color: #fff;
    border-radius: var(--space-2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 30%;
  }
  
  .feature-card img {
    width: 100%;
    height: 100%;
    border-radius: var(--space-2);
    top: 0;
  }
  
  .blog {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: var(--space-1);
  }
  
  input[type="search"] {
    cursor: pointer;
    width: 20px;
    color: white;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    outline: none;
    border: none;
    padding: 4px;
    background: no-repeat 9px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='16' viewBox='0 0 14 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.4766 6.48633C11.4766 9.24775 9.23799 11.4863 6.47656 11.4863C3.71514 11.4863 1.47656 9.24775 1.47656 6.48633C1.47656 3.7249 3.71514 1.48633 6.47656 1.48633C9.23799 1.48633 11.4766 3.7249 11.4766 6.48633ZM9.62534 11.5947C8.71005 12.1601 7.63141 12.4863 6.47656 12.4863C3.16285 12.4863 0.476562 9.80004 0.476562 6.48633C0.476562 3.17262 3.16285 0.486328 6.47656 0.486328C9.79027 0.486328 12.4766 3.17262 12.4766 6.48633C12.4766 8.28581 11.6844 9.90028 10.4297 11L13.5236 14.9629L12.7353 15.5783L9.62534 11.5947Z' fill='white'/%3E%3C/svg%3E");
    font-size: 16px;
    line-height: 24px;
    margin-left: 15px;
    position: relative;
    /* float: right; */
    transition: all 0.3s ease-in-out !important;
    border-bottom: 2px solid transparent;
  }
  
  .header:hover input[type="search"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='16' viewBox='0 0 14 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.4766 6.48633C11.4766 9.24775 9.23799 11.4863 6.47656 11.4863C3.71514 11.4863 1.47656 9.24775 1.47656 6.48633C1.47656 3.7249 3.71514 1.48633 6.47656 1.48633C9.23799 1.48633 11.4766 3.7249 11.4766 6.48633ZM9.62534 11.5947C8.71005 12.1601 7.63141 12.4863 6.47656 12.4863C3.16285 12.4863 0.476562 9.80004 0.476562 6.48633C0.476562 3.17262 3.16285 0.486328 6.47656 0.486328C9.79027 0.486328 12.4766 3.17262 12.4766 6.48633C12.4766 8.28581 11.6844 9.90028 10.4297 11L13.5236 14.9629L12.7353 15.5783L9.62534 11.5947Z' fill='black'/%3E%3C/svg%3E");
  }
  
  input[type="search"]::-webkit-input-placeholder {
    opacity: 0;
  }
  
  input[type="search"]::-moz-placeholder {
    opacity: 0;
  }
  
  input[type="search"]:-ms-input-placeholder {
    opacity: 0;
  }
  
  input[type="search"]::-ms-input-placeholder {
    opacity: 0;
  }
  
  input[type="search"]::placeholder {
    opacity: 0;
  }
  
  input[type="search"]::before {
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    left: 10px;
    top: 50%;
    background-size: contain;
    border-bottom: #090a0a 1px solid;
  }
  
  input[type="search"]:focus {
    width: 70%;
    font-size: 16px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding-left: 40px;
    border-bottom: #090a0a 1px solid !important;
  }
  
  input[type="search"]:not(.focus) {
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s ease-in-out;
  }
  
  .focus {
    width: 70% !important;
    padding-left: 40px !important;
  }
  
  input[type="search"]:focus::-webkit-input-placeholder {
    opacity: 1;
    font-size: small;
    color: rgb(87, 79, 79);
  }
  
  input[type="search"]:focus::-moz-placeholder {
    opacity: 1;
    font-size: small;
    color: rgb(87, 79, 79);
  }
  
  input[type="search"]:focus:-ms-input-placeholder {
    opacity: 1;
    font-size: small;
    color: rgb(87, 79, 79);
  }
  
  input[type="search"]:focus::-ms-input-placeholder {
    opacity: 1;
    font-size: small;
    color: rgb(87, 79, 79);
  }
  
  input[type="search"]:focus::placeholder {
    opacity: 1;
    font-size: small;
    color: rgb(87, 79, 79);
  }
  
  .fa-arrow-right {
    color: #fff;
  }
  
  .blogs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--space-3);
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }
  
  @media screen and (max-width: 992px) {
    .blogs {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media screen and (max-width: 768px) {
    .blogs {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* .mySwiper .blogs {
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: wrap !important;
      justify-content: space-between !important;
      gap: 30px !important;
      padding-top: 15px !important;
      padding-bottom: 15px !important;
  } */
  
  .blog-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: var(--space-1);
    background-color: #fff;
    border-radius: var(--space-2);
  }
  
  .allBlogs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: var(--space-1);
    background-color: #fff;
  }
  
  .blog-image img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
  
    height: 100%;
  }
  
  .blog-info {
    margin-left: var(--space-2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
  }
  
  .blog-info h1 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: 100%;
    padding-top: var(--space-1);
  }
  
  .blog-info p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 18px;
    line-height: 27px;
    color: var(--text-color);
  }
  
  /* .blog-info p, h1, h2, h3, h4, h5, h6, strong {
      font-weight: normal ;
  } */
  
  .blog-date {
    margin-top: 64px;
    margin-bottom: 34px;
  }
  
  .last-footer {
    display: flex;
    gap: 3rem;
  }
  
  footer .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  
  .links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* margin-top: var(--space-2); */
    gap: 10px;
  }
  
  ul {
    margin-top: var(--space-2);
  }
  
  ul li {
    list-style: none;
    color: var(--text-color);
  }
  
  .collections {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr var(--space-3) 1fr var(--space-3) 1fr var(--space-3) 1fr;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--space-3);
  }
  
  .collections-small {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr var(--space-3) 1fr var(--space-3) 1fr;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--space-3);
  }
  
  .collections-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: var(--space-2);
  }
  
  .drop-show {
    height: 44px;
    border-bottom: 1px solid rgb(233, 220, 220);
  }
  
  .filters-container {
    width: 24%;
  }
  
  .filter-close {
    display: none;
  }
  
  .filter-title {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .basket {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: var(--space-3);
  }
  
  .basket-left {
    min-height: 30vh;
    background-color: var(--light-color);
    width: 65%;
    flex-direction: row;
    width: 100%;
  }
  
  .checkout-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 24px;
    padding-bottom: 32px;
    margin-top: 24px;
    min-height: auto;
    width: 35%;
  }
  
  #checkout-left-btn {
    display: none;
  }
  
  #checkout-right-btn button {
    margin-bottom: 0 !important;
  }
  
  @media (max-width: 768px) {
    .basket-left {
      flex-direction: column;
    }
  
    .checkout-right {
      order: 1;
      width: 100% !important;
      gap: 24px;
      display: flex;
      flex-direction: row !important;
      justify-content: space-between !important;
      align-items: flex-start;
      margin-top: 48px !important;
      padding-left: 24px;
    }
  
    .checkout-right img {
      margin-top: 10px;
    }
  
    #checkout-left-btn {
      display: block;
    }
  
    #checkout-right-btn {
      display: none;
    }
  
    .basket-left-top {
      order: 2;
      width: 100% !important;
      margin-top: 0 !important;
    }
  }
  
  @media (max-width: 992px) {
    .basket-right {
      margin-bottom: 32px !important;
    }
  }
  
  .basket-left-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    /* row-gap: 32px; */
    width: 80%;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 32px;
    padding-bottom: 32px;
  }
  
  .basket-right {
    min-height: 20vh;
    background-color: var(--light-color);
    width: 35%;
  }
  
  .upper-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .item-cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: var(--space-2);
  }
  .single_card_item {
    background-color: white;
    width: 100%;
  }
  
  .single-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    /* min-height: 20vh; */
    background-color: var(--gray-color);
    border-radius: var(--space-2);
    width: 100%;
  }
  
  .single_cart_item {
    height: 222px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 100%;
  }
  
  .single_cart_item img {
    height: 100%;
  }
  
  .single-item {
    height: 222px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 100%;
  }
  
  .single-item img {
    height: 100%;
    border-radius: var(--space-2);
  }
  
  .item_cart_info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }
  
  .item-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 50%;
  }
  
  .cancel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: var(--space-2);
  }
  
  .item-button {
    width: 35px;
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    outline: none;
  }
  
  .less-more {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: var(--space-2);
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .price-config {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .check-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    /* justify-content: space-between; */
  }
  
  .check-info:not(:first-child) {
    margin-top: var(--space-3);
  }
  
  .check-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: var(--space-3);
  }
  
  .link {
    text-decoration: underline;
  }
  
  .parent-content {
    width: 50%;
    padding-bottom: 64px;
  }
  
  .upper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: var(--space-2);
    padding: 5px;
  }
  
  .upper-gallery {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    gap: var(--space-3);
  }
  
  .gallery {
      width: 50%;
      height: 60vh;
      display: flex;
      flex-direction: row-reverse;
      gap: 26px;
  }
  
  .options {
    width: 50%;
    height: 70vh;
  }
  
  .options > p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .big-gallery {
    height: 100%;
    width: 70%;
    padding: 1px;
  }
  
  .gallery-image {
    width: 100%;
    height: 100%;
  }
  
  .small-gallery {
      position: relative;
      width: 30%;
      height: 60vh;
      overflow: hidden;
      display: flex;
      flex-direction: column;
  }
  
  .small-gallery::before {
    content: "";
    background-image: url(/public/banner.81139ad.png);
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.1;
  }
  
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  
  .small-gallery .second-swiper-container,
  .small-gallery .swiper-slide {
    width: 100%;
    height: 100%;
  }
  
  .small-gallery .swiper-slide img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    padding-bottom: 16px;
  }
  
  .tabs {
    border-radius: 6px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    list-style: none;
  }
  
  .tab {
    display: none;
  }
  
  .tab:checked:first-of-type ~ .tab-content:first-of-type,
  .tab:checked:nth-of-type(2) ~ .tab-content:nth-of-type(2),
  .tab:checked:nth-of-type(3) ~ .tab-content:nth-of-type(3) {
    opacity: 1;
    -webkit-transition: opacity 0.5s ease-in, -webkit-transform 0.2s ease;
    transition: opacity 0.5s ease-in, -webkit-transform 0.2s ease;
    -o-transition: opacity 0.5s ease-in, transform 0.2s ease;
    transition: opacity 0.5s ease-in, transform 0.2s ease;
    transition: opacity 0.5s ease-in, transform 0.2s ease,
      -webkit-transform 0.2s ease;
    position: relative;
    top: 0;
    z-index: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    text-shadow: 0 0 0;
  }
  
  .tab:checked:nth-of-type(4) ~ .tab__content:nth-of-type(4) {
    opacity: 1;
    -webkit-transition: opacity 0.5s ease-in, -webkit-transform 0.2s ease;
    transition: opacity 0.5s ease-in, -webkit-transform 0.2s ease;
    -o-transition: opacity 0.5s ease-in, transform 0.2s ease;
    transition: opacity 0.5s ease-in, transform 0.2s ease;
    transition: opacity 0.5s ease-in, transform 0.2s ease,
      -webkit-transform 0.2s ease;
    position: relative;
    top: 0;
    z-index: 100;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    text-shadow: 0 0 0;
  }
  
  .tab:first-of-type:not(:last-of-type) + label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .tab:not(:first-of-type):not(:last-of-type) + label {
    border-radius: 0;
  }
  
  .tab:last-of-type:not(:first-of-type) + label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .tab:checked + label {
    background-color: #c89b71;
    color: white;
    font-size: 18px;
    font-weight: 400;
    cursor: default;
    font-family: "TT_FORS_TRIAL_BOLD", sans-serif !important;
  }
  
  .tab + label {
    width: 100%;
    cursor: pointer;
    display: block;
    text-decoration: none;
    font-family: "TT_FORS_TRIAL_REGULAR", sans-serif !important;
    font-size: 16px;
    -webkit-box-flex: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 15px; */
  }
  
  @media (min-width: 768px) {
    .tab + label {
      width: 33%;
    }
    .property-tab + label {
      width: auto;
    }
  }
  
  .tab-content {
      padding: 0 24px ;
      padding-top: 10px;
      padding-bottom: 0;
      background-color: var(--light-color);
      position: absolute;
      width: 100%;
      z-index: -1;
      opacity: 0;
      left: 0;
      -webkit-transform: translateY(-3px);
      -ms-transform: translateY(-3px);
      transform: translateY(-3px);
      font-family: "TT_FORS_TRIAL_REGULAR", sans-serif !important;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-color);
      overflow-x: scroll;
  
  }
  .property-tab {
    display: none;
  }
  .property-tab + label {
    cursor: pointer;
    display: block;
    text-decoration: none;
    font-family: "TT_FORS_TRIAL_REGULAR", sans-serif !important;
    font-size: 16px;
    -webkit-box-flex: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .property-tab:checked + label {
    font-size: 18px;
    font-weight: 400;
    cursor: default;
    font-family: "TT_FORS_TRIAL_BOLD", sans-serif !important;
  }
  .radio-label::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8' viewBox='0 0 14 8' fill='none'%3E%3Cpath d='M13 0.5L7 6.5L1 0.5' stroke='%2301091C'/%3E%3C/svg%3E");
    display: block; /* Ensure it displays as a block */
    color: #000;
    transform: rotate(0deg);
    transition: transform 0.3s ease;
  }
  .property-tab:checked + .radio-label::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8' viewBox='0 0 14 8' fill='none'%3E%3Cpath d='M13 0.5L7 6.5L1 0.5' stroke='%2301091C'/%3E%3C/svg%3E");
    color: #000;
    display: flex;
    align-items: center;
    transform: rotate(180deg);
    transition: transform 0.3s ease; /* Smooth transition when rotating */
  }
  .property-tab:checked:first-of-type ~ .tab-content:first-of-type,
  .property-tab:checked:nth-of-type(2) ~ .tab-content:nth-of-type(2),
  .property-tab:checked:nth-of-type(3) ~ .tab-content:nth-of-type(3) {
    opacity: 1;
    -webkit-transition: opacity 0.5s ease-in, -webkit-transform 0.2s ease;
    transition: opacity 0.5s ease-in, -webkit-transform 0.2s ease;
    -o-transition: opacity 0.5s ease-in, transform 0.2s ease;
    transition: opacity 0.5s ease-in, transform 0.2s ease;
    transition: opacity 0.5s ease-in, transform 0.2s ease,
      -webkit-transform 0.2s ease;
    position: relative;
    top: 0;
    z-index: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    text-shadow: 0 0 0;
  }
  
  .property-tab:checked:nth-of-type(4) ~ .tab__content:nth-of-type(4) {
    opacity: 1;
    -webkit-transition: opacity 0.5s ease-in, -webkit-transform 0.2s ease;
    transition: opacity 0.5s ease-in, -webkit-transform 0.2s ease;
    -o-transition: opacity 0.5s ease-in, transform 0.2s ease;
    transition: opacity 0.5s ease-in, transform 0.2s ease;
    transition: opacity 0.5s ease-in, transform 0.2s ease,
      -webkit-transform 0.2s ease;
    position: relative;
    top: 0;
    z-index: 100;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    text-shadow: 0 0 0;
  }
  
  .property-tab:first-of-type:not(:last-of-type) + label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .property-tab:not(:first-of-type):not(:last-of-type) + label {
    border-radius: 0;
  }
  
  .property-tab:last-of-type:not(:first-of-type) + label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .size-container {
      max-height: 33vh;
      overflow-y: scroll;
  }
  
  .size-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: solid 1px rgb(170, 165, 165);
    height: 52px;
  }
  
  .size-header > span {
    width: 33%;
  }
  
  .size-content {
    max-height: 520px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .checkbox-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .checkbox-container {
    position: relative;
    padding-left: var(--space-2);
    margin-bottom: var(--space-1) !important;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .checkbox-container:hover {
    background-color: #e2dcdc;
  }
  
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    left: 0;
    height: 14px;
    width: 14px;
    border: 1px solid #787878;
  }
  
  .checktitle {
    position: relative;
    padding-left: 8px;
  }
  
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  .checkbox-container input[type="checkbox"]:checked + .checkmark {
    background-color: #a07149;
  }
  
  .checkbox-container input[type="checkbox"]:checked + .checkmark:after {
    content: "";
    display: block;
    width: 12px;
    height: 9px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none"><path d="M1 4L4.5 7.5L11 1" stroke="white" stroke-width="1.5"/></svg>');
    background-size: cover;
    background-position: center;
    top: 2px;
    right: 1px;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkbox-container input[type="checkbox"]:checked ~ .checktitle {
    color: #a07149; /* Change this to your desired color */
  }
  .checkbox-container input[type="checkbox"]:checked {
    background-color: #b4a293; /* Darker background when checked */
  }
  
  .size-content-info {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: var(--space-1);
  }
  
  .size-content-info > span {
    width: 33%;
  }
  
  .content {
    padding-top: var(--space-2);
    display: none;
  }
  
  .show-content .content {
    display: block;
  }
  
  .input-container {
    border: 1px solid var(--gray-color);
    padding: 13px;
    font-size: 16px;
    background-color: var(--gray-color2);
    width: 100%;
    cursor: text;
  }
  
  .input-container:focus {
    outline: none;
  }
  
  .unit {
    font-size: 16px;
    color: var(--text-color);
    margin-left: var(--space-1);
    position: absolute;
    top: 50%;
    right: 20px;
  }
  
  .filter-fields {
    max-height: 200px;
    overflow-y: auto;
    margin-left: 8px;
  }
  
  /* Customize the scrollbar track */
  ::-webkit-scrollbar {
    width: 5px; /* Width of the entire scrollbar */
  }
  
  /* Customize the scrollbar handle (thumb) */
  ::-webkit-scrollbar-thumb {
    background-color: #c6b3a3; /* Scrollbar color */
    /* Optional: adds space around the scrollbar handle */
  }
  
  /* Customize the scrollbar track */
  /* ::-webkit-scrollbar-track {
      background: white; 
  } */
  
  /* Change hover color */
  ::-webkit-scrollbar-thumb:hover {
    background: #b4a293;
  }
  
  .mod {
    display: none;
    position: fixed;
    z-index: 6000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-transition: 2s;
    -o-transition: 2s;
    transition: 2s;
    background: rgba(0, 4, 33, 0.8);
  }
  
  .modalOpen {
    display: block;
  }
  
  .survery-container {
    height: 80vh;
    overflow: scroll;
  }
  
  .modalContent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    /* border-radius: var(--space-2); */
    width: 30%;
  }
  
  .cart-scrol {
    max-height: 60vh;
    overflow-y: scroll;
  }
  
  .regions-container {
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: var(--space-2);
  }
  
  .region {
    background-color: var(--gray-color);
    color: var(--text-color);
    padding: var(--space-2) var(--space-3);
    cursor: pointer;
  }
  
  .added-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .added-items img {
    width: 75px;
    height: 90px;
    margin-right: var(--space-2);
    border-radius: var(--space-2);
  }
  
  .added-item-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .modal-elements {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 50px;
  }
  
  .modal-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: var(--space-2);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .modal-buttons button {
    width: 221px;
  }
  
  .swip {
    width: 45vw;
  }
  
  .colors {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    bottom: 1.2em;
    width: 100%;
  }
  
  .colors .single-color:first-child {
    right: 0;
    z-index: 5;
  }
  
  .colors .single-color:nth-child(2) {
    right: 0.8em;
    z-index: 4;
  }
  
  .colors .single-color {
    width: 16px;
    height: 16px;
    margin-right: 0.1em;
    border-radius: 50%;
    position: absolute;
  }
  
  /* .survey-container {
      max-height: 50vh;
      overflow-y: scroll;
  } */
  
  .single-survey {
    background-color: var(--gray-color);
    border-radius: var(--space-2);
    padding: var(--space-2);
    margin-top: var(--space-2);
  }
  
  .single-survey .survey-title {
    text-align: center;
  }
  
  .single-survey .option-container {
    margin-top: var(--space-2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  
  .single-survey .option-container .single-option {
    margin-right: var(--space-3);
  }
  
  .radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .radiomark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #394b5c;
    border: 2px solid var(--text-color);
  }
  
  .radiomark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .radio-container input:checked ~ .radiomark:after {
    display: block;
  }
  
  .radio-container .radiomark:after {
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #394b5c;
    background-color: var(--text-color);
  }
  
  .radiomark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .text-input {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
    padding-left: 19px;
  }
  
  .dropbtn {
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0 !important;
    /* padding-top : 0 !important; */
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    -webkit-box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    z-index: 2;
  }
  
  .bar-menu {
    display: none;
    margin-left: 32px;
  }
  
  .elements {
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    overflow: hidden;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation: growRight 700ms ease-in-out;
    animation: growRight 700ms ease-in-out;
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right;
  }
  
  .elements.closing {
    animation: shrinkLeft 700ms ease-in-out;
  }
  
  @-webkit-keyframes growRight {
    0% {
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
    }
  
    100% {
      -webkit-transform: scaleX(100%);
      transform: scaleX(100%);
    }
  }
  
  @keyframes growRight {
    0% {
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
    }
  
    100% {
      -webkit-transform: scaleX(100%);
      transform: scaleX(100%);
    }
  }
  
  @-webkit-keyframes shrinkLeft {
    0% {
      -webkit-transform: scaleX(100%);
      transform: scaleX(100%);
    }
  
    100% {
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
    }
  }
  
  @keyframes shrinkLeft {
    0% {
      -webkit-transform: scaleX(100%);
      transform: scaleX(100%);
    }
  
    100% {
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
    }
  }
  
  .open-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: left;
    height: 85%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    font-size: large;
  }
  
  .close-icon {
    /* position: absolute; */
    /* top: 1em; */
    /* right: 1em; */
    display: block !important;
    margin: 0 !important;
    text-align: right;
  }
  
  .search-input {
    margin-right: 16px;
  }
  
  /* .swiper-slide {
      flex-shrink: auto !important;
      height: auto !important;
  } */
  
  .checkmark,
  ::after,
  ::before {
    -webkit-box-sizing: content-box !important;
    box-sizing: content-box !important;
  }
  
  @media only screen and (max-width: 768px) {
    .bar-menu {
      display: inline;
      display: block;
    }
  
    .dropdown,
    .location,
    .services,
    .vertical-line {
      display: none;
    }
  
    .search-input {
      margin-left: 0;
    }
  
    .basket-badge {
      position: absolute;
      right: 0;
    }
  
    input[type="search"]:focus {
      width: 40%;
    }
  
    .focus {
      width: 40%;
    }
  
    .category-container {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }
  
    footer .container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      /* padding: var(--space-3); */
    }
  
    .blog-image {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      width: auto;
    }
  
    .blog-image img {
      height: auto;
      width: 100%;
    }
  
    .swiper-slide {
      -ms-flex-negative: auto !important;
      flex-shrink: auto !important;
      height: auto !important;
    }
  
    .blog-info {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  
    .trend-products {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  
    .allBlogs {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      height: auto;
      width: 100%;
    }
  }
  
  /* .swiper {
      padding-bottom: 10px !important;
  } */
  
  .two-line-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .left {
    margin-left: 16px;
  }
  
  .right {
    margin-right: 1rem;
  }
  
  .orderinfo {
    background-color: var(--light-color);
    margin-top: 15px;
  }
  
  .item-info img {
    width: 200px;
    /* border-radius: var(--space-2); */
    margin-right: 1rem;
  }
  
  .accordion-item {
    border-radius: 0.4rem;
  }
  
  .accordion-item hr {
    border-bottom: 1px solid #ecdfd4;
  }
  
  .accordion-link {
    font-size: 1.6rem;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid #ecdfd4;
  }
  
  .accordion-link > div {
    width: 88%;
  }
  
  .accordion-link h3 {
    font-weight: 500;
    font-size: 20px;
  }
  
  .answer {
    max-height: 0;
    overflow: hidden;
    position: relative;
    transition: max-height 650ms ease;
  }
  
  .answer p {
    /* color: #000; */
    font-size: 15px;
    padding: 1.5rem 1rem;
    padding-right: 4.5rem;
  }
  
  .accordion-item.open .answer {
    max-height: 20rem;
  }
  
  .accordion-link svg {
    transition: transform 0.3s ease;
    margin-right: 1rem;
  }
  
  .accordion-item.open .accordion-link svg {
    transform: rotateX(180deg);
  }
  
  #header-logo {
    background-image: url("../static/logo.svg");
    background-repeat: no-repeat;
    width: 90px;
    height: 35px;
  }
  
  .header:hover #header-logo {
    background-image: url("../static/blue_logo.svg");
    background-repeat: no-repeat;
  }
  
  .scrolled-header #header-logo {
    background-image: url("../static/blue_logo.svg");
    background-repeat: no-repeat;
  }
  .additional-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    margin-left: 16%;
  }
  
  .addcart-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    border: none;
    background-color: #0052d2;
    color: white;
    margin: 16px 0;
    height: 5vh;
  }
  .disabled-cart-button {
    background-color: #e3e3e3;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    border: none;
    cursor: not-allowed;
    margin: 16px auto;
    height: 5vh;
  }
  
  .disabled-cart-button svg path {
    fill: black; /* This will change the SVG path color to black */
  }
  
  .disabled-cart-button svg path {
    fill: black; /* This will change the SVG path color to black */
  }
  
  .addcart-button svg path {
    fill: white; /* This sets the default color when the button is not disabled */
  }
  .paginate {
    display: flex;
    justify-content: center;
    padding-top: 64px;
    padding-bottom: 64px;
  }
  
  .about-section {
    display: flex;
    justify-content: space-between;
  }
  
  .image-container {
    flex: 1;
    max-width: 40%;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
  }
  
  .text-container {
    flex: 2;
    padding-left: 30px;
    padding-top: 16px;
  }
  
  .text-container.two {
    padding-left: 0;
    padding-right: 30px;
  }
  
  .text-container h2 {
    font-size: 24px;
    font-weight: bold;
    color: #1a1a1a;
  }
  
  .text-container p {
    width: 80%;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
  }
  
  .gallery-container {
    padding: 64px 0;
    background-color: #c6b3a3;
    margin-top: 164px;
    margin-bottom: 164px;
  }
  
  .top-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    margin-bottom: 32px;
  }
  
  .bottom-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }
  
  .top-grid img,
  .bottom-grid img {
    width: 100%;
    height: auto;
  }
  
  .production-info {
    background-color: #c6b3a3;
    padding-top: 64px;
    padding-bottom: 96px;
    margin-top: 164px;
    margin-bottom: 164px;
  }
  
  .production-info h2 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 48px;
  }
  
  .years-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 236px;
    color: #fff;
  }
  
  .years-grid p {
    margin: 0;
    font-size: 18px;
  }
  
  .image-text-section {
    text-align: center;
    margin-top: 90px;
  }
  
  .mission-text {
    margin-top: 148px;
    width: 80%;
    display: inline-block;
  }
  
  .about-section-container {
    margin-top: 194px;
  }
  
  .mission-section {
    text-align: center;
    margin-bottom: 196px;
  }
  
  .mission-statement {
    width: 80%;
    display: inline-block;
  }
  
  .method-section-container {
    margin-bottom: 164px;
  }
  
  .method-image-container {
    margin-top: 136px;
    margin-bottom: 144px;
  }
  
  @media (max-width: 768px) {
    .years-grid {
      grid-template-columns: 1fr;
    }
    .image-text-section {
      margin-top: 48px;
    }
    .mission-text {
      margin-top: 124px;
    }
    .about-section-container {
      margin-top: 137px;
    }
    .production-info {
      margin-top: 124px;
    }
    .gallery-container {
      margin-top: 124px;
    }
    .about-section {
      flex-direction: column;
    }
  
    .about-section.two {
      flex-direction: column-reverse;
    }
  
    .image-container,
    .text-container {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  
    .text-container {
      margin-top: 20px;
    }
  
    .text-container p {
      width: 100%;
    }
  
    .top-grid {
      grid-template-columns: 1fr;
      margin-bottom: 0;
    }
  
    .bottom-grid {
      grid-template-columns: 1fr;
    }
  
    .top-grid img,
    .bottom-grid img {
      margin-bottom: 24px;
    }
    .method-section-container {
      margin-bottom: 142px;
    }
    .method-image-container {
      margin-top: 108px;
      margin-bottom: 102px;
    }
  }
  
  @media (max-width: 575px) {
    .method-section-container {
      margin-bottom: 96px;
    }
    .image-text-section {
      margin-top: 40px;
    }
  
    .mission-text {
      margin-top: 96px;
    }
    .about-section-container {
      margin-top: 118px;
    }
    .production-info {
      margin-top: 96px;
    }
    .mission-section {
      text-align: center;
      margin-bottom: 166px;
    }
    .gallery-container {
      margin-top: 116px;
    }
    .method-image-container {
      margin-top: 68px;
      margin-bottom: 68px;
    }
  }
  
  .tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-container .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip-container .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  
  .countdown {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
  .countdown .single-count-content {
    position: relative;
    padding: 0 40px;
    text-align: center;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .countdown .single-count-content {
      padding: 0 30px;
    }
  }
  
  @media (max-width: 767px) {
    .countdown .single-count-content {
      padding: 0 10px;
      padding-right: 15px;
    }
  }
  
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .countdown .single-count-content {
      padding: 0 20px;
    }
  }
  
  .countdown .single-count-content::after {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 50px;
  }
  
  @media (max-width: 767px) {
    .countdown .single-count-content::after {
      top: -10px;
    }
  }
  
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .countdown .single-count-content::after {
      top: -5px;
    }
  }
  
  .countdown .single-count-content:last-child::after {
    display: none;
  }
  
  .countdown .single-count-content .count {
    font-size: 50px;
    letter-spacing: 2px;
  }
  
  @media (max-width: 767px) {
    .countdown .single-count-content .count {
      font-size: 35px;
    }
  }
  
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .countdown .single-count-content .count {
      font-size: 45px;
    }
  }
  
  .countdown .single-count-content .text {
    margin-bottom: 0;
  }
  
  @media (max-width: 767px) {
    .countdown .single-count-content .text {
      font-size: 15px;
    }
  }
  
  main.main-06 {
    overflow: hidden;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url(../static/img/23.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  
  @media (max-width: 1201px) {
    main.main-06 {
      background-image: url(../static/img/24.png);
    }
  }
  
  @media (max-width: 575px) {
    main.main-06 {
      background-image: url(../static/img/25.png);
    }
  }
  
  main .main-wrapper.demo-06 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
  }
  
  main .main-wrapper.demo-06 .hero-area {
    margin-bottom: 100px;
  }
  
  main .main-wrapper.demo-06 .hero-area h1 {
    font-size: 100px;
    font-weight: 700;
    color: #fff;
    font-family: 'TT_FORS_TRIAL_REGULAR', sans-serif;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    main .main-wrapper.demo-06 .hero-area h1 {
      font-size: 80px;
    }
  }
  
  @media (max-width: 767px) {
    main .main-wrapper.demo-06 .hero-area h1 {
      font-size: 40px;
    }
  }
  
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    main .main-wrapper.demo-06 .hero-area h1 {
      font-size: 50px;
    }
  }
  
  main .main-wrapper.demo-06 .hero-area p {
    font-family: 'TT_FORS_TRIAL_REGULAR', sans-serif;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 40px;
  }
  
  main .main-wrapper.demo-06 .hero-area .countdown .single-count-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  
  main .main-wrapper.demo-06 .hero-area .countdown .single-count-content::after {
    content: ":";
    font-size: 54px;
    color: #fff;
    top: 40px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    main
      .main-wrapper.demo-06
      .hero-area
      .countdown
      .single-count-content::after {
      top: 30px;
    }
  }
  
  @media (max-width: 767px) {
    main
      .main-wrapper.demo-06
      .hero-area
      .countdown
      .single-count-content::after {
      top: 33px;
      font-size: 45px;
    }
  }
  
  main .main-wrapper.demo-06 .hero-area .countdown .single-count-content .count {
    font-family: 'TT_FORS_TRIAL_REGULAR', sans-serif;
    color: #fff;
    font-size: 60px;
    font-weight: 700;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    main
      .main-wrapper.demo-06
      .hero-area
      .countdown
      .single-count-content
      .count {
      font-size: 50px;
    }
  }
  
  @media (max-width: 767px) {
    main
      .main-wrapper.demo-06
      .hero-area
      .countdown
      .single-count-content
      .count {
      font-size: 40px;
    }
  }
  
  main .main-wrapper.demo-06 .hero-area .countdown .single-count-content .text {
    font-size: 17px;
    color: rgba(255, 255, 255, 0.5);
  }
  
  main .footer {
    width: 100%;
    margin-bottom: 50px;
    position: absolute;
    bottom: 0;
  }
  
  main .footer p {
    color: rgba(255, 255, 255, 0.7);
    transition: 0.3s;
    font-size: 18px;
  }
  
  main .footer p:hover {
    color: #fff;
  }
  
  @media (max-width: 767px) {
    main .footer p {
      text-align: center;
    }
  }
  
  main .footer .credit {
    text-align: right;
  }
  
  @media (max-width: 767px) {
    main .footer .credit {
      text-align: center;
    }
  
    .countdown .single-count-content:first-child {
      padding-left: 0px;
    }
  }