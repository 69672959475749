body {
    font-family: 'Montserrat', sans-serif;
    min-height: 100vh;
}

a {
    text-decoration: none !important;
    color: inherit !important;
}

th {
    font-weight: inherit;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

.opacity {
    opacity: 0.6;
}

.opacityhover {
    opacity: 0.6;
}

.opacityhover:hover {
    opacity: 1;
}

.opacity {
    opacity: 0.6;
}

.opacityhover {
    opacity: 0.6;
}

.opacityhover:hover {
    opacity: 1;
}

.mr-1 {
    margin-right: 1rem;
}

.mb-05 {
    margin-bottom: 0.5rem;
}

.mb-15 {
    margin-bottom: 1.5rem;
}

.py-1 {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.py-05 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
}

#main {
    min-height: 90vh;
    background-color: #F1F2F4;
    /* display: flex;
    flex-wrap: nowrap; */
}

a:hover {
    text-decoration: none !important;
}

#upperheadercont {
    background-color: #EAEAEA;
}

#upperheader {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.upperlanguage,
.upperlocation,
.uppernav,
.uppercall {
    display: flex;
    flex-wrap: nowrap;
}

.upperlanguage *,
.upperlocation *,
.uppernav *,
.uppercall * {
    margin-top: auto;
    margin-bottom: auto;
}

.upperlanguage span {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.upperlocation span {
    cursor: pointer;
}

#middleheader {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

#middleheadercont {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.logo {
    width: 114px;
    height: 45px;
}

.logocont {
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}



.nav-icon {
    width: 26px;
}

#searchbar {
    display: flex;
    flex-wrap: nowrap;
    margin-top: auto;
    margin-bottom: auto !important;
    margin-right: 1rem;
}

#searchbar .btn,
#searchbar .input-group-prepend {
    border-right: none !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #108637;
    height: 100%;
}

#searchbar .btn {
    background-color: #FBFBFB;
}

#searchbar .btn:hover {
    background-color: #ECECEC;
}

#searchbar .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: #108637;
    border-left: none;
    height: inherit;
}

#searchbar .form-control::-webkit-input-placeholder {
    color: black;
    /*Change the placeholder color*/
    opacity: 0.2;
    /*Change the opacity between 0 and 1*/
    font-size: 0.8rem;
    font-weight: 600;
}

#searchbar .form-control:focus,
#searchbar .btn:focus {
    border-color: #108637;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07) inset, 0 0 0.2rem rgba(16, 134, 55, 0.6);
    outline: 0 none;
}

.middlerightheader {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.middlerightheader .rightnav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.middlerightheader .rightnav .nav-icon {
    margin-left: auto;
    margin-right: auto;
}

.count-absolute {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #108637;
    width: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 15px;
}

#bottomheader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#bottomheader a {
    margin-left: auto;
    margin-right: auto;
}

#bottomheadercont {
    border-bottom: 2px solid rgba(0, 0, 0, 0.25);
}

#bottomheadercont #bottomheader a:last-child {
    padding-right: 0 !important;
}

.greenbutton {
    background-color: #0E8637;
    color: white !important;
}

.redbutton {
    background-color: #EF4141 !important;
    color: white !important;
}

.greembutton img,
.redbutton img {
    filter: invert(1);
}

.button a,
button {
    padding: 10px;
    border-radius: 10px;
    border: none;
}





/* #footer{
    background-color: #F0F2F4;
} */

/* #footer {
    display: flex;
    flex-direction: column;
} */

.topfooter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.leftfooter,
.navfooter {
    display: flex;
    flex-direction: column;
}

.logofooter {
    width: 10vw;
    min-width: 120px;
    margin-bottom: 1rem;
}

#footerbottom {
    background-color: #EAEAEA;
}

.block {
    padding: 1rem 0;
}

/* .btn-primary {
    background-color: inherit!important;
    color: inherit!important;
    border: none!important;
}

.btn-primary:focus {
    border: none!important;
} */

.horizontal-line {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.menuisclose {
    display: none;
}

.menu-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 60001;
    cursor: pointer;
}

.menu-relative {
    position: relative;
    width: 100%;
    height: 100%;
}

.menu-cont {
    position: absolute;
    width: 70%;
    height: 100vh;
    background-color: white;
    transform: translateX(-100%);
}

.menuisopen .menu-cont {
    animation: menuisopen 0.3s ease-out forwards;
}

@keyframes menuisopen {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0%);
    }
}

.menu-cont-relative {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
}

.menu-titles {
    background-color: #108637;
    color: white;
}

.menu-titles .text {
    padding: 1rem;
    border-radius: 15px 0 0 15px;
    transition: ease-out .3s;
    white-space: nowrap;
}

.menu-choosen {
    background-color: #EF4141;
}

.menu-titles .text:hover {
    background-color: #EF4141;
}

.menu-close-btn {
    position: absolute;
    right: 0;
    transform: translateX(100%);
}

.grid {
    display: grid;
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.gap-4 {
    gap: 1rem;
}

.input {
    border-radius: 5px;
    padding: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.4);
}

.buy-substract,
.buy-add {
    background-color: white;
    cursor: pointer;
}

.buy-counter {
    background-color: #0E8637;
    color: white;
    border: none;
}

.buy:not(:last-child),
.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.invert {
    filter: invert(1);
}

.pointer {
    cursor: pointer;
}

.whiteback {
    background-color: white;
    color: black;
}

.fake-input {
    background-color: transparent !important;
    border: none !important;
}

@media print {
    .no-print {
        display: none !important;
    }
}

/* .cart {
    border-radius: 15px;
    background-color: white;
    padding: 2rem;
    border: none;
} */

.per-50 {
    width: calc(50% - 1rem);
}

.per-25 {
    width: calc(25% - 1rem);
}

.per-30 {
    width: calc(100%/3 - 1rem);
}

.per-70 {
    width: calc(100%/3*2 - 1rem);
}

.sales-cart {
    width: calc(100%/3 - 4rem/3);
}

.sales .sales-cart:not(:last-child) {
    margin-right: calc(3rem/2);
}

.sales-cart img {
    width: 100%;
}

#logo {
    width: 50px;
    height: 50px;
}

#header {
    height: 80px;
    padding-top: 15px;
}



/* .search-icon{
    width: 42px;
    height: 44px;
    padding: 10px;
    margin-left: 15px;
} */
.header-icons {
    width: 16px;
    height: 16px;
    margin-left: 20px;
}

.vl {
    border-left: 1px solid rgb(0, 13, 113);
    height: 10px;
    margin-left: 20px;
}

.smm-logo {
    margin-top: 2px;
}

/* .banner img{
    margin-top: 15px;
    height: 450px;
}
.banner {
    border-radius: 1.5rem;
} */

.card-text {
    background-color: rgba(0, 0, 0, 0.782);
    color: white;
    border-radius: 0.75rem;
}

.containers {
    position: relative;
    margin: 0 auto;
    border-radius: 1rem;
}

.containers #img {
    vertical-align: middle;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
}

.containers .contents {
    position: absolute;
    bottom: 0;
    /* background: rgb(0, 0, 0);
    /* Fallback color */
    /* background: rgba(0, 0, 0, 0.8); */
    background: linear-gradient(.313turn, rgba(0, 4, 33, .5), rgba(0, 4, 33, .13));
    /* Black background with 0.5 opacity */
    color: #f1f1f1;
    width: 100%;
    padding: 10px;
    border-radius: 0rem 0rem 1rem 1rem;
    backdrop-filter: blur(15px);
    align-items: center;
}

.arrow-icon {
    width: 5vh;
    height: 5vh;
    border: 1px solid #fff;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 5px;
    margin-left: 15px;
}

.product-card {
    font-family: "rubik-medium", sans-serif;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 289px;
    transition: .37s;
    border-radius: 15px;
    background-color: white;
}

.showButton {
    cursor: not-allowed;
    color: #29488C;
    background-color: hsl(220, 100%, 99%);
    text-align: center;
    border-radius: 15px;
    font-weight: bold;
    font-size: 13px;
    margin: 0 5px 10px 0;
}

.button {
    color: hsl(220, 100%, 99%);
    background-color: #29488C;
    text-align: center;
    border-radius: 15px;
    font-weight: bold;
    font-size: 13px;
    margin: 0 5px 10px 0;

}

.filter-btn {
    background-color: #29488C;
    color: #DFE4EE;
}

.product-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;

}

.product-card:hover button {
    background-color: #29488C;
    color: #DFE4EE;
}

.product-card img {
    width: 100%;
    height: 50%;
    border-radius: 15px;
}

.product-container {
    padding: 2px 16px;
    font-family: "rubik-bold", sans-serif;
}

.swiper-button-prev,
.swiper-button-next {
    display: none !important;
    padding: 25px;
}

.titles {

    font-family: "rubik-bold", sans-serif;
    font-size: 30px;
    line-height: 45px;
    color: #394b5c;
    font-weight: bold;
}

.tab {
    margin: 0;
    background-color: white;
    border-radius: 0 0 15px 15px;
    border: none;
}

.nav-item {
    border-radius: 12px 12px 0 0 !important;
    border: none !important;
}

.card-body {
    border-radius: 15px;
}

.img-cart {
    width: 40px;
    height: 40px;
}

.tip div {
    display: inline-block;
    margin-left: 10px;
}

.tip img {
    float: left;
}

.post {
    background-color: white;
    border-radius: 15px;
}

.post img {
    border-radius: 15px;
}

.leaflet-control-attribution {
    display: none !important;
  }